import styles from './AnalyticsTable.module.css';
import _ from 'lodash'
const AnalyticsTable = ({
    header,
    data,
    thead,
    keys=[]
}) => {
    return (
        <div
            className={styles.table_container}
            style={{ height: "100%", width: "100%" }}>
            <div className={styles.table_header}>
                <h6>{header}</h6>
            </div>
            <div className='table_style_comman'>
                <table className='table table-border'>
                    <thead>
                        <tr className={styles.table_header}>
                            {thead?.map((item, index) => (
                                item && <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => {
                            return (
                                <tr key={index} className={styles.table_body}>
                                    {thead?.map((th, idx) => (
                                        th && <td key={idx} style={{ textAlign: 'left' }}>
                                            {_.get(item,`${[keys[idx]]}`,"00")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default AnalyticsTable;
