import React, { useState } from 'react';
import './SortableTable.css';
import { useLocation, useNavigate } from 'react-router-dom';

const SortableTable = ({ data, type, title }) => {
    const [sortConfig, setSortConfig] = useState({
        key: 'averageAuditScore',
        direction: 'descending',
    });

    const sortedData = React.useMemo(() => {
        let sortableItems = [...data];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [data, sortConfig]);

    const requestSort = key => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getBackgroundColor = score => {
        const alpha = 0.75;

        if (score <= 60) {
            const greenIntensity = (score - 50) / 10;
            return `rgba(237, 110, 110, ${alpha - 0.25 * greenIntensity})`;
        } else {
            const greenIntensity = (score - 60) / 40;
            const r = Math.round(132 + (164 - 132) * greenIntensity);
            const g = Math.round(187 + (192 - 187) * greenIntensity);
            const b = Math.round(76 + (75 - 76) * greenIntensity);
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }
    };

    const renderSortIcon = key => {
        if (sortConfig.key !== key) return null;
        return sortConfig.direction === 'ascending' ? (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 16 16'
                role='img'
                aria-label='chevronup icon'
                className='sort-icon'
                width='10'
                height='10'>
                <path d='M1.47 11.53a.75.75 0 0 0 1.06 0L8 6.06l5.47 5.47a.75.75 0 1 0 1.06-1.06l-6-6a.75.75 0 0 0-1.06 0l-6 6a.75.75 0 0 0 0 1.06z'></path>
            </svg>
        ) : (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 16 16'
                role='img'
                aria-label='chevrondown icon'
                className='sort-icon'
                width='10'
                height='10'>
                <path d='M1.47 4.47a.75.75 0 0 1 1.06 0L8 9.94l5.47-5.47a.75.75 0 1 1 1.06 1.06l-6 6a.75.75 0 0 1-1.06 0l-6-6a.75.75 0 0 1 0-1.06z'></path>
            </svg>
        );
    };

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = data => {
        navigate(`${location.pathname}/${type.toLowerCase()}`, {
            state: { name: data, type: type },
        });
    };

    return (
        <>
            <div className='table_style_comman'>
                <table className='table table-border'>
                    <thead>
                        <tr>
                            <th>{title}</th>
                            <th
                                className='text_icon_line'
                                onClick={() => requestSort('averageAuditScore')}
                                style={{ textAlign: 'left' }}>
                                Quality Score{' '}
                                {renderSortIcon('averageAuditScore')}
                            </th>
                            <th
                                onClick={() => requestSort('noOfAudits')}
                                style={{ textAlign: 'left' }}>
                                Number of Evaluations{' '}
                                {renderSortIcon('noOfAudits')}
                            </th>
                            <th
                                onClick={() => requestSort('noOfFatalAudits')}
                                style={{ textAlign: 'left' }}>
                                Number of Fatal Evaluations{' '}
                                {renderSortIcon('noOfFatalAudits')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((row, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: 'left' }}>
                                    {row.name}
                                </td>
                                <td
                                    style={{
                                        textAlign: 'left',
                                    }}>
                                    {row.averageAuditScore}
                                </td>
                                <td
                                    style={{
                                        textAlign: 'left',
                                    }}>
                                    {row.noOfAudits}
                                </td>
                                <td
                                    style={{
                                        textAlign: 'left',
                                    }}>
                                    {row.noOfFatalAudits}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SortableTable;
