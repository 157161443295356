import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
} from 'recharts';
import CustomLegend from '../LineGraph/CustomLegend';

const SentimentBarGraph = ({ data, yaxis }) => {
    return (
        <ResponsiveContainer width='100%' height={400}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' label={{
                        value: 'Time Period',
                        position: 'insideBottom',
                        offset: 0,
                        style: {
                            textAnchor: 'middle',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                        },
                    }}
                    tick={{ fontFamily: 'Inter, sans-serif', fontSize: '10px' }}/>
                <XAxis dataKey='date' />
                {/* <YAxis>
          <Label value={yaxis} angle={-90} position='insideLeft' />
        </YAxis> */}
                <YAxis
                    label={{
                        value: yaxis,
                        angle: -90,
                        position: 'insideLeft',
                        offset: 20,
                        style: {
                            textAnchor: 'middle',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                        },
                    }}
                />
                <Tooltip />
                <Legend content={<CustomLegend />}
                    iconType='circle'
                    layout='horizontal'
                    verticalAlign='top'
                    align='left'
                    wrapperStyle={{
                        paddingBottom: 40,
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                />
                <Bar
                    dataKey='Positive'
                    fill='#17b26a'
                    name='Positive'
                    barSize={20}
                    radius={[4, 4, 0, 0]}
                />
                <Bar
                    dataKey='Neutral'
                    fill='#072b15'
                    name='Neutral'
                    barSize={20}
                    radius={[4, 4, 0, 0]}
                />
                <Bar
                    dataKey='Negative'
                    fill='#ff2b2b'
                    name='Negative'
                    barSize={20}
                    radius={[4, 4, 0, 0]}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default SentimentBarGraph;
