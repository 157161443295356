import React, { useEffect, useState } from 'react';
import './App.css'; // Import the global styles
import SortableTable from './components/SortableTable/SortableTable';
import LineGraph from './components/LineGraph/LineGraph';
import SentimentBarGraph from './components/SentimentBarGraph/SentimentBarGraph';
import { useSelector } from 'react-redux';
import { loggedInUser } from '../store/auth/selectors';
import * as AuthSelector from '../store/auth/selectors';
import OverallSentiment from './components/overall_sentiment/OverallSentiment';
import DateRangePickerComp from '../views/modules/social/AuditSheet/DateRangePickerComp';
import { DateRangeFunction } from '../CommonLogic';
import * as moment from 'moment';
import { client } from '../services/apiService';
import _ from 'lodash'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';

const QAauditMetrics = [
    "Total Audit Count",
    "Total Audits Accepted",
    "Total Audits Pending",
    "Total Rebuttals Pending",
    "Average Quality Score",
    "Unique Agents Evaluated",
    "Auto Failed Calls",
    "% Auto Failed Calls",
    // "Average Evaluation Time",
    // "Average AHT"
];

const QAManager = [
    'Total Audit Count',
    'Total Audits Accepted',
    'Total Audits Pending',
    'Total Rebuttals Pending',
    'Average Quality Score',
    'Unique Agents Evaluated',
    'Auto Failed Calls',
    'Total Audits Pending to be Acknowledged',
    '% Auto Failed Calls',
    // 'Average AHT',
    // 'Average Evaluation Time',
];

function DemoChart() {
    const userInfo = useSelector(loggedInUser);
    const [auditMetrics, setAuditMetrics] = useState([])
    const [auditMetricsData, setAuditMetricsData] = useState({})
    const [loader, setLoader] = useState(false);
    const [sentimentData, setSentimentData] = useState({});
    
    useEffect(() => {
        if (userInfo.role == "QA") {
            setAuditMetrics(QAauditMetrics)
            handleDateChange(DateRangeFunction(0, 6))
        }
        if (userInfo.role == "QA-Manager") {
            setAuditMetrics(QAManager)
            handleDateChange(DateRangeFunction(0, 6))
        }
    }, [userInfo])

    const getDateFromTimestamp = (date) => {
        const dateObject = new Date(date);
    
        const year = dateObject.getFullYear(); 
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
        const day = String(dateObject.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };
      
    const getSentimentData = async (auditMetricsData, dateRange) => {
        try {
            const res = await axios.post(
                `https://omind-computelayer-testserver.site/cci_analysis/analyze_agents`,
                {
                    agent_ids: auditMetricsData?.uniqueAgentIds,
                    start_date: getDateFromTimestamp(dateRange?.start_date),
                    end_date: getDateFromTimestamp(dateRange?.end_date),
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            setSentimentData(res?.data);
            setLoader(false);
        } catch {
            setLoader(false);
        }
    };

    async function handleDateChange(range) {
        setLoader(true);
        const { startDate, endDate } = range;
        const start = moment(startDate).toISOString();
        const end = moment(endDate).toISOString();
        const params = `organisationId={{org-id}}&startDate=${start}&endDate=${end}&offset=${new Date().getTimezoneOffset()}`

        if (userInfo.role == "QA") {
            const { data } = await client.get(`/qa-analytics-info?${params}`);
            setAuditMetricsData(data);
            getSentimentData(data,{start_date: start, end_date: end})
        }
        if (userInfo.role == "QA-Manager") {
            const { data } = await client.get(`/qamanager-analytics-info?${params}`);
            setAuditMetricsData(data);
            getSentimentData(data,{start_date: start, end_date: end})
        }
    }

    return (
        <div className='page-wrapper no_overflow_x'>
            <div className='content pb-0'>
                <div className='row'>
                    <div className='app-container-demo'>
                        <div className='dashboard'>
                            <div
                                className='large-card title_legend_line quality_score_graph_only'
                                style={{ width: '30%' }}>
                                <div className='card-title'>
                                    <h6>Date Range Selector</h6>
                                </div>
                                <div className='card-title'>
                                    <DateRangePickerComp
                                        defaultRange={DateRangeFunction(0, 6)}
                                        onDateChange={handleDateChange}
                                    />
                                </div>
                            </div>

                            {/* loader */}
                            {loader && <div className='loader_comp'>
                                <Spinner animation="border" variant="light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>}
                            <div className={loader ? 'loader_blur_area' : ''}>

                                <div className='small-cards'>
                                    {auditMetrics.map((el, idx) => {
                                        return (
                                            <div className='small-card' key={idx}>
                                                <div className='dashcard-container'>
                                                    <div className='card-body-demo-demo'>
                                                        <div className='scalar-title-container'>
                                                            <h3 className='scalar-title'>
                                                                {el}
                                                                <h4 className='scalar-value'>
                                                                    {_.get(auditMetricsData, `cardInformation[${el}]`, "0")}
                                                                </h4>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                                <div className='large-cards'>
                                    <div className='large-card mt-3'>
                                        <div className='card-title'>
                                            Agent Wise Average Audit Score
                                        </div>
                                        <div className='large-card-content'>
                                            <SortableTable
                                                data={_.get(auditMetricsData, `agentWiseAverageAuditScore`, [])}
                                                type='Agent'
                                                title='Agent Name'
                                            />
                                        </div>
                                    </div>

                                    <div className='large-card mt-3'>
                                        <div className='card-title'>
                                            QA Wise Average Audit Score
                                        </div>
                                        <div className='large-card-content'>
                                            <SortableTable
                                                data={_.get(auditMetricsData, `auditorWiseAverageAuditScore`, [])}
                                                type='QA'
                                                title='QA Name'
                                            />
                                        </div>
                                    </div>

                                    <div className='large-card title_legend_line'>
                                        <div className='card-title'>
                                            Audit Trend
                                        </div>
                                        <div className='large-card-content'>
                                            <LineGraph
                                                data={_.get(auditMetricsData, `auditTrend`, [])}
                                                yaxis={'Number of Audits'}
                                            />
                                        </div>
                                    </div>

                                    <div className='large-card title_legend_line'>
                                        <div className='card-title'>
                                            Fatal Audits Trend
                                        </div>
                                        <div className='large-card-content'>
                                            <LineGraph
                                                data={_.get(auditMetricsData, `fatalAuditTrend`, [])}
                                                yaxis='Number of Fatal Audits'
                                            />
                                        </div>
                                    </div>

                                    {userInfo && userInfo.role !== 'Agent' && (
                                        <>
                                            {/* <div className='large-card'>
                                                <div className='card-title'>
                                                    Overall Sentiment
                                                </div>
                                                <div className='large-card-content'>
                                                    <SentimentBarGraph
                                                        data={_.get(auditMetricsData, "overallSentiment", [])}
                                                        yaxis='Overall Sentiment'
                                                    />
                                                </div>
                                            </div> */}

                                            <div className='large-card title_legend_line'>
                                                <div className='card-title'>
                                                    Customer Sentiment
                                                </div>
                                                <div className='large-card-content'>
                                                    <SentimentBarGraph
                                                        data={_.get(auditMetricsData, "customerSentiment", [])}
                                                        yaxis='Customer Sentiment'
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className='large-card title_legend_line'>
                                                <div className='card-title'>
                                                    Agent Sentiment
                                                </div>
                                                <div className='large-card-content'>
                                                    <SentimentBarGraph
                                                        data={_.get(auditMetricsData, "AgentSentiment", [])}
                                                        yaxis='Agent Sentiment'
                                                    />
                                                </div>
                                            </div> */}

                                            {/* <div className='large-card title_legend_line'>
                                                <div className='card-title'>
                                                    Datewise Sentiment Analysis
                                                </div>
                                                <div className='large-card-content'>
                                                    <SentimentBarGraph
                                                        data={_.get(auditMetricsData, "overallSentiment", [])}
                                                        yaxis='Sentiment Count'
                                                        yxis={"Department"}
                                                    />
                                                </div>
                                            </div> */}
                                        </>
                                    )}

                                </div>

                                {userInfo && userInfo.role !== 'Agent' && (
                                    <div className='mt-3'>
                                        <OverallSentiment sentimentData = {sentimentData} />
                                    </div>
                                )}
                            </div>
                            {/* loader end */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemoChart;