const agentDataSentiment = [
    {
        noOfFatalAudits: 1,
        noOfAudits: 24,
        averageAuditScore: '94%',
        name: 'Surya Kumar',
    },
    {
        noOfFatalAudits: 2,
        noOfAudits: 22,
        averageAuditScore: '92%',
        name: 'Kunal Pratap',
    },
    {
        noOfFatalAudits: 2,
        noOfAudits: 19,
        averageAuditScore: '91%',
        name: 'Ananda Kumar',
    },
    {
        noOfFatalAudits: 3,
        noOfAudits: 21,
        averageAuditScore: '88%',
        name: 'Shailesh Kumar',
    },
    {
        noOfFatalAudits: 5,
        noOfAudits: 26,
        averageAuditScore: '87%',
        name: 'Sakshi Singh',
    },
];

const qaDataSentiment = [
    {
        noOfFatalAudits: 2,
        noOfAudits: 38,
        averageAuditScore: '96%',
        name: 'Shekhar Suman',
    },
    {
        noOfFatalAudits: 3,
        noOfAudits: 36,
        averageAuditScore: '91%',
        name: 'Anand Rao',
    },
    {
        noOfFatalAudits: 8,
        noOfAudits: 38,
        averageAuditScore: '83%',
        name: 'Rohan Joshi',
    },
];

const dailyAuditCountsByDepartment = [
    {
        auditDate: '2024-09-01',
        departments: [
            { department: 'Patient Support', count: 2 },
            { department: 'Device Delivery', count: 3 },
        ],
    },
    {
        auditDate: '2024-09-02',
        departments: [
            { department: 'Patient Support', count: 3 },
            { department: 'Device Delivery', count: 4 },
        ],
    },
    {
        auditDate: '2024-09-03',
        departments: [
            { department: 'Patient Support', count: 2 },
            { department: 'Device Delivery', count: 4 },
        ],
    },
    {
        auditDate: '2024-09-04',
        departments: [
            { department: 'Patient Support', count: 3 },
            { department: 'Device Delivery', count: 5 },
        ],
    },
    {
        auditDate: '2024-09-05',
        departments: [
            { department: 'Patient Support', count: 2 },
            { department: 'Device Delivery', count: 2 },
        ],
    },
];

const monthlyCSATTrend = [
    {
        auditDate: 'Jul 2024',
        departments: [
            { department: 'Team', count: 92.39 },
            { department: 'Process', count: 75.27 },
        ],
    },
    {
        auditDate: 'Aug 2024',
        departments: [
            { department: 'Team', count: 83.39 },
            { department: 'Process', count: 85.27 },
        ],
    },
    {
        auditDate: 'Sep 2024',
        departments: [
            { department: 'Team', count: 73.39 },
            { department: 'Process', count: 77.27 },
        ],
    },
];

const fatalAuditCounts = [
    {
        auditDate: '2024-09-01',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-02',
        departments: [
            { department: 'Patient Support', count: 1 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-03',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-04',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
    {
        auditDate: '2024-09-05',
        departments: [
            { department: 'Patient Support', count: 0 },
            { department: 'Device Delivery', count: 0 },
        ],
    },
];

const overallSentimentData = [
    {
        time: '01-09-2024',
        Positive: 14,
        Neutral: 6,
        Negative: 3,
    },
    {
        time: '02-09-2024',
        Positive: 9,
        Neutral: 5,
        Negative: 2,
    },
    {
        time: '03-09-2024',
        Positive: 9,
        Neutral: 5,
        Negative: 2,
    },
    {
        time: '04-09-2024',
        Positive: 9,
        Neutral: 5,
        Negative: 2,
    },
    {
        time: '05-09-2024',
        Positive: 9,
        Neutral: 5,
        Negative: 2,
    },
];

const key_performance_metrics = {
    data: [
        {
            metrics: 'Quality Score',
            self: '92%',
            team_qa: '90%',
            overall_qa: '87%',
            process: '91%',
        },
        {
            metrics: 'Total Fatal Count',
            self: '3',
            team_qa: '7',
            overall_qa: '10',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            self: '27',
            team_qa: '38',
            overall_qa: '56',
            process: '56',
        },
        {
            metrics: 'Business Critical',
            self: '86%',
            team_qa: '91%',
            overall_qa: '98%',
            process: '98%',
        },
        {
            metrics: 'Customer Critical',
            self: '95%',
            team_qa: '84%',
            overall_qa: '91%',
            process: '91%',
        },
        {
            metrics: 'Compliance Critical',
            self: '98%',
            team_qa: '93%',
            overall_qa: '97%',
            process: '97%',
        },
    ],
    legends: ['Metrics', 'Self', 'Team QA', 'Process'],
};

const key_performance_metrics_qamanager = {
    data: [
        {
            metrics: 'Quality Score',
            qa: '90%',
            overall_qa: '95%',
            team: '90%',
            process: '95%',
        },
        {
            metrics: 'Total Fatal Count',
            qa: '4',
            overall_qa: '7',
            team: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            qa: '20',
            overall_qa: '30',
            team: '38',
            process: '56',
        },
        {
            metrics: 'Business Critical',
            qa: '80%',
            overall_qa: '70%',
            team: '91%',
            process: '98%',
        },
        {
            metrics: 'Customer Critical',
            qa: '60%',
            overall_qa: '30%',
            team: '84%',
            process: '91%',
        },
        {
            metrics: 'Compliance Critical',
            qa: '70%',
            overall_qa: '90%',
            team: '93%',
            process: '97%',
        },
    ],
    legends: ['Metrics', 'Team', 'Process'],
};

const key_performance_metrics_agent = {
    data: [
        {
            metrics: 'Quality Score',
            agent: '94%',
            team: '92%',
            process: '96%',
        },
        {
            metrics: 'Total Fatal Count',
            agent: '3',
            team: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            agent: '27',
            team: '35',
            process: '48',
        },
        {
            metrics: 'Business Critical',
            agent: '86%',
            team: '88%',
            process: '94%',
        },
        {
            metrics: 'Customer Critical',
            agent: '98%',
            team: '96%',
            process: '99%',
        },
        {
            metrics: 'Compliance Critical',
            agent: '97%',
            team: '93%',
            process: '97%',
        },
    ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
};

const key_performance_metrics_tl = {
    data: [
        {
            metrics: 'Quality Score',
            agent: '94%',
            overall_agent: '70',
            team: '92%',
            process: '96%',
        },
        {
            metrics: 'Total Fatal Count',
            agent: '3',
            overall_agent: '7',
            team: '7',
            process: '10',
        },
        {
            metrics: 'Total Non-Fatal Count',
            agent: '27',
            overall_agent: '30',
            team: '35',
            process: '48',
        },
        {
            metrics: 'Business Critical',
            agent: '86%',
            overall_agent: '70%',
            team: '88%',
            process: '94%',
        },
        {
            metrics: 'Customer Critical',
            agent: '98%',
            overall_agent: '30%',
            team: '96%',
            process: '99%',
        },
        {
            metrics: 'Compliance Critical',
            agent: '97%',
            overall_agent: '90%',
            team: '93%',
            process: '97%',
        },
    ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
};

const performance_metrics = {
    data: [
        {
            metrics: 'AHT (min)',
            team: '3.3',
            process: '4.1',
        },
        {
            metrics: 'Hold (sec)',
            team: '27.2',
            process: '29.5',
        },
        {
            metrics: 'Talk-Time',
            team: '187.8',
            process: '191.4',
        },
        {
            metrics: 'ACW (min)',
            team: '11.3',
            process: '0.9',
        },
        {
            metrics: 'FCR',
            team: '87%',
            process: '85%',
        },
    ],
    legends: ['Metrics', 'Team', 'Process'],
};
const customerSentimentData = [
    {
        department: '01-09-2024',
        Positive: 14,
        Neutral: 6,
        Negative: 3,
    },
    {
        department: '02-09-2024',
        Positive: 17,
        Neutral: 6,
        Negative: 4,
    },
    {
        department: '03-09-2024',
        Positive: 18,
        Neutral: 3,
        Negative: 2,
    },
    {
        department: '04-09-2024',
        Positive: 17,
        Neutral: 8,
        Negative: 2,
    },
    {
        department: '05-09-2024',
        Positive: 18,
        Neutral: 7,
        Negative: 2,
    },
];

const agentSentimentData = [
    {
        department: '01-09-2024',
        Positive: 14,
        Neutral: 6,
        Negative: 3,
    },
    {
        department: '02-09-2024',
        Positive: 17,
        Neutral: 7,
        Negative: 3,
    },
    {
        department: '03-09-2024',
        Positive: 16,
        Neutral: 3,
        Negative: 4,
    },
    {
        department: '04-09-2024',
        Positive: 17,
        Neutral: 8,
        Negative: 2,
    },
    {
        department: '05-09-2024',
        Positive: 18,
        Neutral: 8,
        Negative: 1,
    },
];

const datewiseSentimentData = [
    {
        department: '01-09-2024',
        Positive: 34,
        Neutral: 6,
        Negative: 3,
    },
    {
        department: '02-09-2024',
        Positive: 37,
        Neutral: 7,
        Negative: 3,
    },
    {
        department: '03-09-2024',
        Positive: 36,
        Neutral: 3,
        Negative: 4,
    },
    {
        department: '04-09-2024',
        Positive: 37,
        Neutral: 8,
        Negative: 2,
    },
    {
        department: '05-09-2024',
        Positive: 28,
        Neutral: 8,
        Negative: 1,
    },
];

const other_performance_metrics = {
    data: [
        {
            metrics: 'Opening',
            qa: 3.5,
            team: 4.0,
            process: 4.5,
        },
        {
            metrics: 'Vocabulary',
            qa: 3.2,
            team: 3.1,
            process: 3.1,
        },
        {
            metrics: 'Product Knowledge',
            qa: 2.5,
            team: 2.0,
            process: 2.1,
        },
        {
            metrics: 'Process Knowledge',
            qa: 1.5,
            team: 1.9,
            process: 1.8,
        },
        {
            metrics: 'Sales Skills',
            qa: 1.5,
            team: 1.5,
            process: 1.7,
        },
        {
            metrics: 'Customer Service Skills',
            qa: 3.5,
            team: 3.2,
            process: 3.1,
        },
        {
            metrics: 'Active Listening',
            qa: 3.5,
            team: 3.4,
            process: 3.1,
        },
    ],
    legends: ['Metrics', 'QA', 'Team', 'Process'],
};

const other_performance_metrics_qamanager = {
    data: [
        {
            metrics: 'Opening',
            qa: 3.5,
            team: 4.0,
            overall_qa: 4.0,
            process: 4.5,
        },
        {
            metrics: 'Vocabulary',
            qa: 3.2,
            team: 3.1,
            overall_qa: 3.4,
            process: 3.1,
        },
        {
            metrics: 'Product Knowledge',
            qa: 2.5,
            team: 2.1,
            overall_qa: 2.4,
            process: 2.1,
        },
        {
            metrics: 'Process Knowledge',
            qa: 1.5,
            team: 1.4,
            overall_qa: 1.5,
            process: 1.8,
        },
        {
            metrics: 'Sales Skills',
            qa: 1.5,
            team: 1.1,
            overall_qa: 1.6,
            process: 1.7,
        },
        {
            metrics: 'Customer Service Skills',
            qa: 3.5,
            team: 4.0,
            overall_qa: 4.2,
            process: 3.1,
        },
        {
            metrics: 'Active Listening',
            qa: 3.5,
            team: 3.4,
            overall_qa: 3.0,
            process: 3.1,
        },
    ],
    legends: ['Metrics', 'Team', 'Process'],
};

const other_performance_metrics_agent = {
    data: [
        {
            metrics: 'Opening',
            agent: 3.5,
            team: 4.0,
            process: 4.5,
        },
        {
            metrics: 'Vocabulary',
            agent: 3.2,
            team: 3.1,
            process: 3.1,
        },
        {
            metrics: 'Product Knowledge',
            agent: 2.5,
            team: 2.1,
            process: 2.1,
        },
        {
            metrics: 'Process Knowledge',
            agent: 1.5,
            team: 1.4,
            process: 1.8,
        },
        {
            metrics: 'Sales Skills',
            agent: 1.5,
            team: 1.1,
            process: 1.7,
        },
        {
            metrics: 'Customer Service Skills',
            agent: 3.5,
            team: 4.0,
            process: 3.1,
        },
        {
            metrics: 'Active Listening',
            agent: 3.5,
            team: 3.4,
            process: 3.1,
        },
    ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
};

const other_performance_metrics_tl = {
    data: [
        {
            metrics: 'Opening',
            agent: 3.5,
            team: 4.0,
            overall_agent: 4.0,
            process: 4.5,
        },
        {
            metrics: 'Vocabulary',
            agent: 3.2,
            team: 3.1,
            overall_agent: 3.4,
            process: 3.1,
        },
        {
            metrics: 'Product Knowledge',
            agent: 2.5,
            team: 2.1,
            overall_agent: 2.4,
            process: 2.1,
        },
        {
            metrics: 'Process Knowledge',
            agent: 1.5,
            team: 1.4,
            overall_agent: 1.5,
            process: 1.8,
        },
        {
            metrics: 'Sales Skills',
            agent: 1.5,
            team: 1.1,
            overall_agent: 1.6,
            process: 1.7,
        },
        {
            metrics: 'Customer Service Skills',
            agent: 3.5,
            team: 4.0,
            overall_agent: 4.2,
            process: 3.1,
        },
        {
            metrics: 'Active Listening',
            agent: 3.5,
            team: 3.4,
            overall_agent: 3.0,
            process: 3.1,
        },
    ],
    legends: ['Metrics', 'Agent', 'Team', 'Process'],
};

const agent_leaderboard = {
    data: [
        {
            agent_name: 'Sidhant',
            quality_score: '80%',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        },
    ],
    legends: [
        'Agent Name',
        'Quality Score',
        'Number of Evaluations',
        'Number of Fatal Evaluations',
    ],
};

const agent_leaderboard_tl = {
    data: [
        {
            agent_name: 'Sidhant',
            quality_score: '94%',
            number_of_evaluations: '24',
            number_of_fatal_evaluations: '1',
        },
        {
            agent_name: 'Sumitra',
            quality_score: '92%',
            number_of_evaluations: '22',
            number_of_fatal_evaluations: '2',
        },
        {
            agent_name: 'Shekhar',
            quality_score: '91%',
            number_of_evaluations: '19',
            number_of_fatal_evaluations: '2',
        },
        {
            agent_name: 'Kunal',
            quality_score: '88%',
            number_of_evaluations: '21',
            number_of_fatal_evaluations: '3',
        },
        ,
        {
            agent_name: 'Shailesh',
            quality_score: '87%',
            number_of_evaluations: '26',
            number_of_fatal_evaluations: '5',
        },
    ],
    legends: [
        'Agent Name',
        'Quality Score',
        'Number of Evaluations',
        'Number of Fatal Evaluations',
    ],
};

const qa_wise_average_audit_score = {
    data: [
        {
            qa_name: 'Silpa',
            quality_score: '80%',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        },
    ],
    legends: [
        'QA Name',
        'Quality Score',
        'Number of Evaluations',
        'Number of Fatal Evaluations',
    ],
};

const qa_wise_average_audit_score_tl = {
    data: [
        {
            qa_name: 'Silpa',
            quality_score: '80%',
            number_of_evaluations: '15',
            number_of_fatal_evaluations: '1',
        },
    ],
    legends: [
        'QA Name',
        'Quality Score',
        'Number of Evaluations',
        'Number of Fatal Evaluations',
    ],
};

const surging_topic = [
    {
        name: 'Home Sleep Apnea Testing (HSAT)',
        value: 75,
    },
    {
        name: 'Mobile Cardiac Telemetry (MCT)',
        value: 72,
    },
    {
        name: 'Overnight Pulse Oximetry (POX)',
        value: 72,
    },
    {
        name: 'Real-time Diagnostics',
        value: 71,
    },
    {
        name: 'Remote Patient Monitoring',
        value: 71,
    },
    {
        name: 'EEG Insomnia Testing',
        value: 65,
    },
    {
        name: 'Billing and Reimbursement Issues',
        value: 64,
    },
    {
        name: 'Integration with Telehealth',
        value: 63,
    },
    {
        name: 'Accurate Diagnostic Reports',
        value: 62,
    },
    {
        name: 'User-Friendly Testing Devices',
        value: 61,
    },
];

const VirtuOxDataGraph = {
    agentDataSentiment,
    qaDataSentiment,
    dailyAuditCountsByDepartment,
    fatalAuditCounts,
    overallSentimentData,
    customerSentimentData,
    agentSentimentData,
    datewiseSentimentData,
    performance_metrics,
    monthlyCSATTrend,
    key_performance_metrics,
    key_performance_metrics_agent,
    key_performance_metrics_qamanager,
    key_performance_metrics_tl,
    other_performance_metrics,
    other_performance_metrics_agent,
    other_performance_metrics_tl,
    other_performance_metrics_qamanager,
    agent_leaderboard,
    agent_leaderboard_tl,
    qa_wise_average_audit_score,
    qa_wise_average_audit_score,
    qa_wise_average_audit_score_tl,
    surging_topic,
};

export default VirtuOxDataGraph;
